var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-sm-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Tif")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Operaciones")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Inspecciones")])])])])])]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card"},[_vm._m(1),_c('ul',{staticClass:"nav nav-tabs",attrs:{"id":"myTab","role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"id":"tab-Principal","data-toggle":"tab","href":"#Principal"},on:{"click":function($event){return _vm.cambiaTab('principal')}}},[_vm._v("Principal")])]),_c('li',{staticClass:"nav-item"},[(_vm.inspeccion_id)?_c('a',{staticClass:"nav-link",attrs:{"id":"tab-Gestion","data-toggle":"tab","href":"#Gestion"},on:{"click":function($event){return _vm.cambiaTab('gestiona')}}},[_vm._v("Gestionar Inspección")]):_vm._e()])]),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade active show",attrs:{"id":"Principal"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-8"},[_c('label',[_vm._v("Formato de Inspección")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                          _vm.$v.form.formato_inspeccion.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                        ],attrs:{"placeholder":"Formato Inspección","label":"nombre","options":_vm.listasForms.formatos_inspeccion,"filterable":false,"disabled":_vm.estado_documento == 2},on:{"search":_vm.buscarFormatosInspeccion},model:{value:(_vm.form.formato_inspeccion),callback:function ($$v) {_vm.$set(_vm.form, "formato_inspeccion", $$v)},expression:"form.formato_inspeccion"}})],1),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"fecha_fin"}},[_vm._v("Fecha vencimiento")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fecha_v),expression:"form.fecha_v"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.fecha_v.$invalid ? 'is-invalid' : 'is-valid',attrs:{"type":"date","disabled":_vm.estado_documento == 2},domProps:{"value":(_vm.form.fecha_v)},on:{"change":function($event){return _vm.validarFechaVenc()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fecha_v", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"hora_fin"}},[_vm._v("Hora vencimiento")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.hora_v),expression:"form.hora_v"},{name:"mask",rawName:"v-mask",value:({
                          mask: '99:99',
                          hourFormat: '24',
                        }),expression:"{\n                          mask: '99:99',\n                          hourFormat: '24',\n                        }"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.hora_v.$invalid ? 'is-invalid' : 'is-valid',attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.form.hora_v)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "hora_v", $event.target.value)}}})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-2"},[_c('label',[_vm._v("Cabezote")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                          _vm.$v.form.cabezote.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                        ],attrs:{"placeholder":"Placa","label":"placa","options":_vm.listasForms.cabezotes,"filterable":false,"disabled":_vm.estado_documento == 2},on:{"search":_vm.buscarCabezotes,"input":_vm.validaRemolque},model:{value:(_vm.form.cabezote),callback:function ($$v) {_vm.$set(_vm.form, "cabezote", $$v)},expression:"form.cabezote"}}),(_vm.form.cabezote)?_c('div',[(
                            _vm.form.cabezote.ultimo_registro &&
                              _vm.form.cabezote.ultimo_registro != ''
                          )?_c('div',[(
                              _vm.form.cabezote.ultimo_registro
                                .fecha_hora_ultimo_reporte < _vm.fechaCorte
                            )?_c('span',{staticClass:"badge badge-warning"},[_vm._v(" Ultimo reporte: "+_vm._s(_vm.form.cabezote.ultimo_registro .fecha_hora_ultimo_reporte)+" ")]):_c('span',{staticClass:"badge badge-success"},[_vm._v(" Ultimo reporte: "+_vm._s(_vm.form.cabezote.ultimo_registro .fecha_hora_ultimo_reporte)+" ")])]):_c('div',[_c('span',{staticClass:"badge badge-danger"},[_vm._v("No presenta fecha de reporte")])])]):_vm._e()],1),_c('div',{staticClass:"form-group col-md-2"},[_c('label',[_vm._v("Remolque")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                          _vm.form.cabezote && _vm.form.cabezote.tipo_vehiculo_id == 1
                            ? _vm.$v.form.remolque.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                            : '',
                          _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                        ],attrs:{"placeholder":"Placa","label":"placa","options":_vm.listasForms.remolques,"filterable":false,"disabled":!_vm.form.cabezote ||
                            _vm.form.cabezote.tipo_vehiculo_id != 1 ||
                            _vm.estado_documento == 2},on:{"search":_vm.buscarRemolques},model:{value:(_vm.form.remolque),callback:function ($$v) {_vm.$set(_vm.form, "remolque", $$v)},expression:"form.remolque"}})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('label',[_vm._v("Empresa")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                          _vm.$v.form.empresa.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                        ],attrs:{"placeholder":"Empresa","label":"razon_social","options":_vm.listasForms.empresas,"filterable":false,"disabled":_vm.estado_documento == 2},on:{"search":_vm.buscarEmpresas},model:{value:(_vm.form.empresa),callback:function ($$v) {_vm.$set(_vm.form, "empresa", $$v)},expression:"form.empresa"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"fecha_inicio"}},[_vm._v("Fecha Inicial")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fecha_inicio),expression:"form.fecha_inicio"}],staticClass:"form-control form-control-sm",attrs:{"type":"date","readonly":""},domProps:{"value":(_vm.form.fecha_inicio)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fecha_inicio", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"hora_inicio"}},[_vm._v("Hora Inicial")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.hora_inicio),expression:"form.hora_inicio"},{name:"mask",rawName:"v-mask",value:({
                          mask: '99:99',
                          hourFormat: '24',
                        }),expression:"{\n                          mask: '99:99',\n                          hourFormat: '24',\n                        }"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.hora_inicio.$invalid
                            ? 'is-invalid'
                            : 'is-valid',attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.form.hora_inicio)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "hora_inicio", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"fecha_fin"}},[_vm._v("Fecha Final")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fecha_fin),expression:"form.fecha_fin"}],staticClass:"form-control form-control-sm",attrs:{"type":"date","readonly":""},domProps:{"value":(_vm.form.fecha_fin)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fecha_fin", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"hora_fin"}},[_vm._v("Hora Final")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.hora_fin),expression:"form.hora_fin"},{name:"mask",rawName:"v-mask",value:({
                          mask: '99:99',
                          hourFormat: '24',
                        }),expression:"{\n                          mask: '99:99',\n                          hourFormat: '24',\n                        }"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.hora_fin.$invalid
                            ? 'is-invalid'
                            : 'is-valid',attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.form.hora_fin)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "hora_fin", $event.target.value)}}})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',[_vm._v("Responsable")]),_c('input',{staticClass:"form-control form-control-sm",attrs:{"type":"text","readonly":""},domProps:{"value":_vm.responsable.nombre}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.responsable_id),expression:"form.responsable_id"}],staticClass:"form-control form-control-sm p-0",attrs:{"type":"hidden"},domProps:{"value":(_vm.form.responsable_id)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "responsable_id", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Observaciones")]),_c('br'),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.observaciones),expression:"form.observaciones"}],staticClass:"form-control form-control-sm",attrs:{"vtype":"date","rows":"2","disabled":_vm.estado_documento == 2},domProps:{"value":(_vm.form.observaciones)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "observaciones", $event.target.value)}}})])])])]),(_vm.inspeccion_id && _vm.formato_inspeccion)?_c('div',{staticClass:"tab-pane fade",attrs:{"id":"Gestion"}},[_c('TifInspeccionGestiona',{ref:"TifInspeccionGestiona"}),_c('TifInspeccionImages')],1):_vm._e()]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('button',{staticClass:"btn btn-lg bg-secondary",on:{"click":function($event){return _vm.back()}}},[_c('i',{staticClass:"fas fa-reply"}),_c('br'),_vm._v("Volver ")]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(
                      !_vm.$v.form.$invalid &&
                        _vm.tab == 'principal' &&
                        (_vm.estado_documento == 1 || _vm.estado_documento == null)
                    ),expression:"\n                      !$v.form.$invalid &&\n                        tab == 'principal' &&\n                        (estado_documento == 1 || estado_documento == null)\n                    "}],staticClass:"btn btn-lg bg-primary",on:{"click":_vm.validarOperacion}},[_c('i',{staticClass:"fas fa-paper-plane"}),_c('br'),_vm._v("Guardar ")]),(_vm.estado_documento == 1 && _vm.tab == 'gestiona')?_c('button',{staticClass:"btn btn-lg bg-primary",on:{"click":function($event){return _vm.$refs.TifInspeccionGestiona.save()}}},[_c('i',{staticClass:"fas fa-paper-plane"}),_c('br'),_vm._v("Guardar ")]):_vm._e()]),(
                    _vm.$store.getters.can('tif.inspecciones.edit') &&
                      _vm.estado_documento == 1 &&
                      _vm.tab == 'gestiona'
                  )?_c('div',{staticClass:"col-md-4"},[_c('label',{staticClass:"m-0"},[_vm._v("Estado:")]),(
                      _vm.$store.getters.can('tif.inspecciones.edit') &&
                        _vm.estado_documento == 1 &&
                        _vm.tab == 'gestiona'
                    )?_c('select',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.$refs.TifInspeccionGestiona.estadoForm.estado_documento
                    ),expression:"\n                      $refs.TifInspeccionGestiona.estadoForm.estado_documento\n                    "}],staticClass:"form-control form-control-sm",attrs:{"disabled":(_vm.$store.getters.can(
                        'tif.inspecciones.edit'
                      ) &&
                        _vm.estado_documento != 1) ||
                        !_vm.$refs.TifInspeccionGestiona.flagGestionado},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.$refs.TifInspeccionGestiona.estadoForm, "estado_documento", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.$refs.TifInspeccionGestiona.save()}]}},_vm._l((_vm.$refs.TifInspeccionGestiona.listaForm
                        .estados_documento),function(estado){return _c('option',{key:estado.numeracion,domProps:{"value":estado.numeracion}},[_vm._v(" "+_vm._s(estado.descripcion)+" ")])}),0):_vm._e()]):_vm._e()])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Inspecciones")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 pt-2 pb-2"},[_c('h5',[_vm._v("Inspecciones")])])
}]

export { render, staticRenderFns }